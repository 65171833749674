import styled from 'styled-components';

export const PageTitle = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: 900;
  font-size: 28px;
  text-transform: uppercase;
  line-height: 1;
  color: ${({ theme }) => theme.colors.black};

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 42px;
  }
`;

export const SectionTitle = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: 700;
  font-size: 28px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.black};

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 36px;
  }
`;

export const Header = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: 700;
  font-size: 24px;
  line-height: 1.1;
  color: ${({ theme }) => theme.colors.black};
`;

export const Paragraph = styled.p`
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.black};

  strong {
    font-weight: 700;
  }
`;

export const BlockLink = styled.a`
  display: block;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: 700;
  font-size: 18px;
  line-height: 1.2;
  text-decoration: underline;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};
`;

export const InlineLink = styled.a`
  display: inline;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  text-decoration: underline;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    font-weight: 700;
  }
`;
