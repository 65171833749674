import React from 'react';
import styled from 'styled-components';
import { Link } from 'wouter';
import { ReactComponent as Logo } from './logo3.svg';

const Container = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  padding: 4px;
  box-sizing: border-box;

  @media (min-width: 768px) {
    padding: 8px 16px;
  }
`;

const BannerCopy = styled.a`
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: 700;
  font-size: 12px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

const NavRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;

  svg {
    cursor: pointer;
  }
`;

const MenuRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MenuLink = styled.a`
  display: block;
  text-decoration: none;
  margin-left: 12px;

  @media (min-width: 768px) {
    margin-left: 24px;
  }

  span {
    font-family: ${({ theme }) => theme.fontFamily};
    font-weight: 500;
    font-size: 12px;
    line-height: 1.1;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.black};

    @media (min-width: 414px) {
      font-size: 14px;
    }

    &.-large {
      display: none;
    }

    @media (min-width: 768px) {
      &.-small {
        display: none;
      }

      &.-large {
        display: block;
      }
    }
  }

  &:hover {
    span {
      text-decoration: underline;
    }
  }
`;

const ExpandableMenuLink = styled(MenuLink)`
  position: relative;

  &:hover {
    span {
      text-decoration: none;
    }
  }

  div {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    width: 100%;
    min-width: 180px;
    padding-top: 12px;
  }

  ul {
    display: ${({ isDrawerOpen }) => isDrawerOpen ? 'flex' : 'none'};
    flex-direction: column;
    list-style: none;
    padding: 12px;
    background-color: ${({ theme }) => theme.colors.black};
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -5px;
      left: 50%;
      transform: translateX(-50%);

      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid ${({ theme }) => theme.colors.black};
    }

    li {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      font-family: ${({ theme }) => theme.fontFamily};
      font-weight: 500;
      font-size: 16px;
      line-height: 1.1;
      text-decoration: none;
      white-space: nowrap;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.white};

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export default function Navigation(props) {
  const {
    bannerCopy = '',
    bannerLink = '',
  } = props;

  const hasBanner = !!bannerCopy && !!bannerLink;

  const isTouchDevice = (navigator.maxTouchPoints || 'ontouchstart' in document.documentElement);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const drawerRef = React.useRef(null);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (isDrawerOpen && drawerRef.current && !drawerRef.current.contains(event.target)) {
        setIsDrawerOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isDrawerOpen, setIsDrawerOpen]);

  return (
    <Container>
      {hasBanner && (
        <BannerContainer>
          <BannerCopy href={bannerLink}>
            {bannerCopy}
          </BannerCopy>
        </BannerContainer>
      )}
      <NavRow>
        <Link href="/">
          <Logo />
        </Link>
        <MenuRow>
          <ExpandableMenuLink
            onMouseEnter={() => !isTouchDevice && setIsDrawerOpen(true)}
            onMouseLeave={() => !isTouchDevice && setIsDrawerOpen(false)}
            onClick={() => isTouchDevice && setIsDrawerOpen(!isDrawerOpen)}
            isDrawerOpen={isDrawerOpen}
            ref={drawerRef}
            as="span"
          >
            <span className="-small">guides</span>
            <span className="-large">election reporting guides</span>
            <div>
              <ul>
                <li><Link href="/post/election-season-101">Election Season 101</Link></li>
                <li><Link href="/post/election-season-201">Election Season 201</Link></li>
                <li><Link href="/post/election-season-301">Election Season 301</Link></li>
              </ul>
            </div>
          </ExpandableMenuLink>
          <MenuLink href="https://secure.actblue.com/donate/data-for-progress">
            <span className="-small">support</span>
            <span className="-large">support data for progress</span>
          </MenuLink>
        </MenuRow>
      </NavRow>
    </Container>
  );
}
