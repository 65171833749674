import styled from 'styled-components';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};
  padding-left: 16px;
  padding-right: 16px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
`;

export default Section;
