import React from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { Route, Switch } from 'wouter';
import Navigation from 'components/Navigation';
import Footer from 'components/Footer';
import theme from './theme';
import config from 'content/config.json';

import useBuildTimestampRefresh from './hooks/useBuildTimestampRefresh';

const GlobalAppStyle = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.colors.white};
  }
`;

const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const Post = React.lazy(() => import('./pages/Post'));

// TODO: Error boundary
export default function App() {
  useBuildTimestampRefresh();

  return (
    <React.Suspense fallback={null}>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <GlobalAppStyle />
          <Navigation
            bannerCopy={config.navigation.bannerCopy}
            bannerLink={config.navigation.bannerLink}
          />
          <Switch>
            <Route path="/" component={Dashboard} />
            <Route path="/about" component={(props) => <Post {...props} params={{ slug: 'about' }} />} />
            <Route path="/state/:stateCode" component={Dashboard} />
            <Route path="/post/:slug" component={Post} />
          </Switch>
          <Footer />
        </React.Fragment>
      </ThemeProvider>
    </React.Suspense>
  );
}
