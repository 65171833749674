const theme = {
  breakpoints: {
    tablet: '768px',

  },
  colors: {
    black: '#000000',
    white: '#FFFFFF',
    lightGray: '#F9F9F9',
    darkGray: '#949494',
    hoverLightGray: '#e0e0e0',
    hoverDarkGray: '#3d3d3d',
    blue: '#3B9BF3',
    red: '#F3463B',
    purple: '#801AD0',
  },
  fontFamily: 'proxima-nova, sans-serif',
  maxWidth: '1140px',
};

export default theme;
