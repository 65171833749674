/**
 * During the build process, we populate /build-timestamp with the same value
 * we set process.env.REACT_APP_BUILD_TIMESTAMP to.
 *
 * The frontend fetches this file periodically, and if the value in this file
 * differs from process.env.REACT_APP_BUILD_TIMESTAMP, then we know that the
 * code has changed and we should reload the page to get the newest code.
 */
import { useEffect } from 'react';

const CHECK_INTERVAL = 60 * 1000;

export default function useBuildTimestampRefresh() {
  useEffect(() => {
    if (!process.env.REACT_APP_BUILD_TIMESTAMP) {
      console.warn('Not using build timestamp system; no REACT_APP_BUILD_TIMESTAMP defined');
      return;
    }

    const buildTimestamp = Number(process.env.REACT_APP_BUILD_TIMESTAMP);
    console.log(`Build timestamp: ${buildTimestamp}`);

    const intervalHandle = setInterval(() => {
      fetch('/build-timestamp')
        .then(r => {
          if (r.status === 200) {
            return r.text();
          } else {
            return Promise.reject(new Error(`Error fetching build timestamp`));
          }
        })
        .then(tsString => {
          if (Number(tsString) !== buildTimestamp) {
            // Delay a bit so everything doesn't refresh all at the same time
            const delay = Math.round(Math.random() * CHECK_INTERVAL);

            console.warn(`Detected a new build. Will refresh in ${delay / 1000} seconds.`);

            setTimeout(() => {
              document.location.reload();
            }, delay);
          }
        })
        .catch(err => console.error(err));
    }, CHECK_INTERVAL);

    return () => clearInterval(intervalHandle);
  }, []);
}
