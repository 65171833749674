import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn: 'https://8f2774cccb954b0aa289b0485ebc92cb@o335887.ingest.sentry.io/5502097',
//     integrations: [
//       new Integrations.BrowserTracing(),
//     ],
//     tracesSampleRate: 1.0,
//   });
// }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
