import React from 'react';
import styled from 'styled-components';
import Section from 'components/Section';
import { Header, InlineLink } from 'components/Typography';
import { ReactComponent as TwitterLogo } from './twitter.svg';

const Container = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  padding-bottom: 24px;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Column = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    width: calc(33.33% - 12px);
    margin-bottom: 0;
  }

  ${Header}, ${InlineLink} {
    margin-bottom: 12px;
  }

  ${InlineLink} {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const SocialRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;

  ${InlineLink} {
    margin-left: 6px;
    margin-bottom: 0;
  }
`;

const Copyright = styled.span`
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: 100;
  font-size: 18px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.black};
`;

const PartnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 48px;
`;

const PartnerTitle = styled.h4`
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: 100;
  font-size: 28px;
  line-height: 1.1;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  width: 100%;
  margin-bottom: 24px;
`;

export default function Footer() {
  return (
    <Container>
      <Section>
        <PartnerContainer>
          <PartnerTitle>In Partnership With</PartnerTitle>
          <img src={`${process.env.PUBLIC_URL}/partners/resilient-democracy.png`} alt="Resilient Democracy" />
        </PartnerContainer>
        <LinksContainer>
          <Column>
            <Header>About</Header>
            <InlineLink href="/about">Election Integrity Project</InlineLink>
            <InlineLink href="https://www.dataforprogress.org/about">Data For Progress</InlineLink>
            <InlineLink href="https://www.voteamerica.com/">VoteAmerica</InlineLink>
            <InlineLink href="/about">Election Integrity Project Team</InlineLink>
          </Column>
          <Column>
            <Header>Election reporting guides</Header>
            <InlineLink href="/post/election-season-101">Election Season 101</InlineLink>
            <InlineLink href="/post/election-season-201">Election Season 201</InlineLink>
            <InlineLink href="/post/election-season-301">Election Season 301</InlineLink>
          </Column>
          <Column>
            <Header>Contact</Header>
            <InlineLink href="mailto:enip@dataforprogress.com">enip@dataforprogress.org</InlineLink>
            <SocialRow>
              <TwitterLogo />
              <InlineLink href="https://twitter.com/DataProgress">@DataProgress</InlineLink>
            </SocialRow>
          </Column>
        </LinksContainer>
      </Section>
      <Copyright>
        © Data for Progress 2020
      </Copyright>
    </Container>
  )
}
